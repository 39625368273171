import React from "react"
import "../scss/bootstrap-custom.scss"
import "../lib/animate-3.7.2.css"

import Header from "./header"
import Footer from "./footer"
import LinkBand from "./linkband"
import SEO from "./seo"

const Layout = ({ showEu2020, location, description, lang, meta, title, children }) => {
  return (
    <>
      <SEO location={location} description={description} lang={lang} meta={meta} title={title}/>
      <Header lang={lang} showEu2020={showEu2020}/>
      <main>{children}</main>
      <LinkBand lang={lang}/>
      <Footer lang={lang}/>
    </>
  )
}

export default Layout
