import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import CardColumns from "react-bootstrap/CardColumns"

import Layout from "../components/layout"
import Counters from "../components/counters"
import AdiLogo from "../components/adi-logo"
import MateszeLogo from "../components/matesze-logo"
import CzrsHrLogo from "../components/czrs-hr-logo"
import LinkBand from "../components/linkband"
import PostsList from "../components/postlist"
import Carousel from "react-bootstrap/Carousel"

const IndexPage = ({ location, data }) => {

  const title = "Home"
  const subtitle = "Ismerje meg a segítő kutyák világát!"

  return (
    <Layout showEu2020={true} location={location} title={title} description={subtitle}>

      <div style={{ position: "absolute", zIndex: "1", width: "100%" }}>
        <Container className="text-primary text-center mb-3 mt-5">
          <Row>
            <Col>
              <h1 className="text-white animated fadeInUp">AURA Segítő Kutya Alapítvány</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className="text-white animated fadeInUp">
                {subtitle}
              </h3>
            </Col>
          </Row>
        </Container>
      </div>

      <Carousel>
        <Carousel.Item>
          <Link title="Rólunk" to="/rolunk/">
            <Img style={{ position: "static" }} fixed={data.aboutUs.childImageSharp.fixed}/>
            <Carousel.Caption>
              <h2>Rólunk</h2>
              <p>Mindent megmutatunk!</p>
            </Carousel.Caption>
          </Link>
        </Carousel.Item>
        <Carousel.Item>
          <Link title="Terápiás kutyák" to="/tudastar/terapias/">
            <Img style={{ position: "static" }} fixed={data.therapy.childImageSharp.fixed}/>
            <Carousel.Caption>
              <h2>Terápiás</h2>
              <p>Képzett kutyák sokrétű alkalmazáshoz</p>
            </Carousel.Caption>
          </Link>
        </Carousel.Item>
        <Carousel.Item>
          <Link title="Mozgássérültet segítő kutya" to="/tudastar/mozgasserultsegito/">
            <Img style={{ position: "static" }} fixed={data.mobility.childImageSharp.fixed}/>
            <Carousel.Caption>
              <h2>Mozgássérültet segítő</h2>
              <p>A mindennapi tevékenységek ellátásához segítő társ</p>
            </Carousel.Caption>
          </Link>
        </Carousel.Item>
        <Carousel.Item>
          <Link title="Rohamjelző kutya" to="/tudastar/rohamjelzo/">
            <Img style={{ position: "static" }} fixed={data.diabetes.childImageSharp.fixed}/>
            <Carousel.Caption>
              <h2>Rohamjelző</h2>
              <p>Epilepszia, diabétesz vagy más krónikus, rohamszerű állapotok jelzésére</p>
            </Carousel.Caption>
          </Link>
        </Carousel.Item>
        <Carousel.Item>
          <Link title="Személyi segítő kutya" to="/tudastar/szemelyisegito/">
            <Img style={{ position: "static" }} fixed={data.personal.childImageSharp.fixed}/>
            <Carousel.Caption>
              <h2>Személyi segítő</h2>
              <p>A személyre szabott társ</p>
            </Carousel.Caption>
          </Link>
        </Carousel.Item>
      </Carousel>

      <LinkBand/>

      <Container fluid className="text-center">
        <Link title="Hírek" to="/hirek/">
          <h2>Hírek</h2>
        </Link>
        <PostsList postEdges={data.news.edges}/>
      </Container>

      <LinkBand/>

      <Container fluid={true} className="mt-5 text-center">
        <CardColumns>
          <Card border="primary">
            <Card.Body>
              <h3>Küldetésünk</h3>
              <Card.Text>
                Az AURA Alapítvány kitűzött célja, hogy a kutyás terápia
                módszerét és a segítő kutyák alkalmazásának előnyeit széles
                körben megismertesse és elterjessze.
              </Card.Text>
              <Button href="/rolunk/">Ismerjen meg minket!</Button>
            </Card.Body>
          </Card>
          <Card border="primary">
            <Card.Body>
              <h3>A csapat</h3>
              <Card.Text>
                Ismerje meg az AURA csapatát közelebbről is.
              </Card.Text>
              <Button href="/rolunk/tagok/" variant="primary">
                A csapat
              </Button>
            </Card.Body>
          </Card>
          <Card border="primary">
            <Card.Body>
              <h3>AURA számokban</h3>
              <Counters
                members={49}
                dogs={53}
                yearsActive={18}
                cities={16}
                locations={39}
                personsTherapy={785}
              />
            </Card.Body>
          </Card>
          <Card border="primary">
            <Card.Body>
              <h3>Átláthatóság</h3>
              <Card.Text>
                Az átláthatóság, az elszámoltathatóság és az integritás az AURA
                működésének három legfontosabb alapelve. Tekintsen bele a
                dokumentumainkba.
              </Card.Text>
              <Button href="/atlathatosag/" variant="primary">
                Dokumentumok
              </Button>
            </Card.Body>
          </Card>
          <Card border="primary">
            <Card.Body>
              <h3>Miért a kutya?</h3>
              <Card.Text>
                Az ember és a kutya között megfigyelhető társas viselkedési párhuzamok magyarázzák a
                kutya alkalmasságát mindazokra a feladatokra, amelyeket napjainkban ellát.
              </Card.Text>
              <Button href="/tudastar/miert-a-kutya/" variant="primary">
                Tovább
              </Button>
            </Card.Body>
          </Card>
          <Card border="primary">
            <Card.Body>
              <h3>Terápiás kutya</h3>
              <Card.Text>
                A gyógypedagógiai, a szociális szolgáltatások területén pedagógiai, pszichológiai,
                pszichiátriai, konduktív pedagógiai habilitációs, illetve rehabilitációs
                folyamatban alkalmazott kutya.
              </Card.Text>
              <Button href="/tudastar/terapias/" variant="primary">
                Tovább
              </Button>
            </Card.Body>
          </Card>
          <Card border="primary">
            <Card.Body>
              <h3>Személyi segítőkutya</h3>
              <Card.Text>
                A fogyatékos személyt önálló életvitelében segítő feladatokra kiképzett kutya.
              </Card.Text>
              <Button href="/tudastar/szemelyisegito/" variant="primary">
                Tovább
              </Button>
            </Card.Body>
          </Card>
          <Card border="primary">
            <Card.Body>
              <h3>Mozgássérült segítő kutya</h3>
              <Card.Text>
                A mozgáskorlátozott személyt mindennapi tevékenységeinek ellátásában segítő
                feladatokra kiképzett kutya.
              </Card.Text>
              <Button href="/tudastar/mozgasserultsegito/" variant="primary">
                Tovább
              </Button>
            </Card.Body>
          </Card>
          <Card border="primary">
            <Card.Body>
              <h3>Rohamjelző kutya</h3>
              <Card.Text>
                Az epilepsziával élő személy vagy más krónikus, rohamszerű állapotoktól
                veszélyeztetett személy segítésére kiképzett kutya.
              </Card.Text>
              <Button href="/tudastar/rohamjelzo/" variant="primary">
                Tovább
              </Button>
            </Card.Body>
          </Card>
          <Card border="primary">
            <Card.Body>
              <h3>Kapcsolatok</h3>
              <Container>
                {/*<Row>*/}
                {/*  <Col>*/}
                {/*    <a*/}
                {/*      href="https://assistancedogsinternational.org/"*/}
                {/*      aria-label="Assistance Dogs International"*/}
                {/*      target="_blank"*/}
                {/*      rel="noopener noreferrer"*/}
                {/*    >*/}
                {/*      <div style={{ margin: "auto", maxWidth: "276px" }}>*/}
                {/*        <AdiLogo/>*/}
                {/*      </div>*/}
                {/*    </a>*/}
                {/*    <p>*/}
                {/*      Az AURA felvétele az Assistance Dogs International Európai*/}
                {/*      szervezetébe folyamatban van.*/}
                {/*    </p>*/}
                {/*  </Col>*/}
                {/*</Row>*/}
                <Row>
                  <Col>
                    <a
                      href="http://czrs.hr/"
                      aria-label="Centar Za Rehabilitaciju Silver"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div style={{ margin: "auto", maxWidth: "313px" }}>
                        <CzrsHrLogo/>
                      </div>
                    </a>
                    <p>
                      Az AURA partnere a horvátországi Centar za Rehabilitaciju
                      Silver központnak.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <a
                      href="http://matesze.hu/"
                      aria-label="Magyar Terápiás és Segítőkutyák Szövetsége"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div style={{ margin: "auto", maxWidth: "90px" }}>
                        <MateszeLogo/>
                      </div>
                    </a>
                    <p>Az AURA tagja a MATESZ Egyesületnek.</p>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </CardColumns>
      </Container>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    news: allMarkdownRemark(
      limit: 3
      filter: { fields: { category: { eq: "hirek" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            category
          }
          excerpt
          timeToRead
          frontmatter {
            title
            description
            date
          }
        }
      }
    }
    aboutUs: file(relativePath: { eq: "photo/Szuper.jpg" }) {
      childImageSharp {
        fixed(quality: 90, height: 600) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    therapy: file(relativePath: { eq: "photo/Csoportkép.jpg" }) {
      childImageSharp {
        fixed(quality: 90, height: 600) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    mobility: file(relativePath: { eq: "photo/Szabadban.jpg" }) {
      childImageSharp {
        fixed(quality: 90, height: 600) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    diabetes: file(relativePath: { eq: "photo/Zsömi index.jpg" }) {
      childImageSharp {
        fixed(quality: 90, height: 600) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    personal: file(relativePath: { eq: "photo/ozzy-oli-tin.jpg" }) {
      childImageSharp {
        fixed(quality: 90, height: 600) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
