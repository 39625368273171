import React from "react"
import PostsListCard from "./postlistcard"
import CardColumns from "react-bootstrap/CardColumns"

const PostsList = ({ postEdges }) => {
  return (
    <CardColumns>
      {postEdges.map(({ node }) => {
        return <PostsListCard key={node.fields.slug} {...node} />
      })}
    </CardColumns>
  )
}

export default PostsList
