import React from "react"
import { Link } from "gatsby"
import { Card } from "react-bootstrap"

const PostsListCard = ({ frontmatter, fields, excerpt }) => {
  const title = frontmatter.title || fields.slug

  return (
    <Card>
      <Card.Header as="h5">
        <Link to={`${fields.slug}`}>{title}</Link>
      </Card.Header>
      <Card.Body>
        <div
          dangerouslySetInnerHTML={{
            __html: frontmatter.description || excerpt,
          }}
        />
      </Card.Body>
    </Card>
  )
}

export default PostsListCard
