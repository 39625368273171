import PropTypes from "prop-types"
import React from "react"
import { FaDog, FaHeartbeat, FaMapMarker, FaUser, FaCity, FaChild } from "react-icons/fa"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Counters = ({ members, dogs, yearsActive, cities, locations, personsTherapy }) => (
  <Container>
    <Row>
      <Col>
        <div className="text-center">
          <h4>
            <FaDog />
            <br />
            {dogs}
          </h4>
          <p>Kiképzett kutya</p>
        </div>
      </Col>
      <Col className="text-center">
        <h4>
          <FaUser />
          <br />
          {members}
        </h4>
        <p>Tagunk van</p>
      </Col>
    </Row>
    <Row>
      <Col>
        <div className="text-center">
          <h4>
            <FaHeartbeat />
            <br />
            {yearsActive}
          </h4>
          <p>Éve létezünk</p>
        </div>
      </Col>
      <Col>
        <div className="text-center">
          <h4>
            <FaCity />
            <br />
            {cities}
          </h4>
          <p>Városban vagyunk jelen</p>
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div className="text-center">
          <h4>
            <FaChild />
            <br />
            {personsTherapy}
          </h4>
          <p>Fő részesül terápiában/hét</p>
        </div>
      </Col>
      <Col>
        <div className="text-center">
          <h4>
            <FaMapMarker />
            <br />
            {locations}
          </h4>
          <p>Terápiás helyszíneink</p>
        </div>
      </Col>
    </Row>
  </Container>
)

Counters.propTypes = {
  members: PropTypes.number,
  dogs: PropTypes.number,
  yearsActive: PropTypes.number,
  locations: PropTypes.number,
}

Counters.defaultProps = {
  members: 0,
  dogs: 0,
  yearsActive: 0,
  locations: 0,
}

export default Counters
